import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  initialize() {
    Rails.ajax({
      type: 'GET',
      url: Routes.apartment_image_data_urls_url(this.data.get('id')),
      success: ((data) => {
        this.dataUrls = data.data_urls
      })
    })
  }

  onDownloadClick() {
    let i = 0;
    let id = setInterval(() => {
      if (i >= this.dataUrls.length) {
         clearInterval(id);
         return;
      }

      const image = this.dataUrls[i];
      i++;
      const a = document.createElement('a');
      a.href = image
      a.download = `${this.data.get('id')}-${i}`
      a.style.position = 'absolute'
      a.style.left = '-9999px'
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, 500)
  }
}
